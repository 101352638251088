import React, { useState, useEffect } from "react";
import { withOktaAuth, useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import LogRocket from "logrocket";

import MenuService from "../services/menuService";

import spinner from "../images/ajax-loader.gif";
import "./menu.css";

const Menu = props => {
  const [pageHeading, setPageHeading] = useState("We're signing you into your portal...");
  const [menuItems, setMenuItems] = useState([]);
  const history = useHistory();

  useEffect(() => {
    props.oktaAuth.session.get().then(session => {
      if (session.status === 'ACTIVE') {
        props.oktaAuth.getUser().then(user => {
          LogRocket.identify(user.sub, {
            name: user.name,
            email: user.email
          });
          MenuService.getData(user.sub).then(
            result => {
              if (result && result.length === 1) {
                window.location.href = result[0].URL;
              } else if (result && result.length === 0) {
                setPageHeading("You don't have access.  Please contact your advisor practice.");
              } else {
                setMenuItems(result);
                setPageHeading("Please Select Your Portal");
              }
            },
            error => {
              console.log(error);
            }
          );
        });
      } else {
        history.push("/login")
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oktaAuth]);

  const { oktaAuth } = useOktaAuth();

  const onSignOut = async () => {
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut()
      .then(function () {
        console.log("successfully logged out");
      })
      .catch(function (err) {
        console.error("sign out error");
        console.error(err);
      })
      .then(function () {
        // Reload app in unauthenticated state
        window.location.reload();
      });
  };

  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-md-6 mx-auto drop-shadow p-5">
          <h3 className="text-center pb-3">{pageHeading}</h3>
          {menuItems.map(item => (
            <a key={item.Title} href={item.URL} className="plain-text">
              <div className="card flex-row mb-2">
                <div className="menu-item card-header border-0">
                  <img height="50" width="50" src={require(`../images/vendor-logos/${item.LogoFile}.jpg`).default} alt="Logo" />
                </div>
                <div className="card-block p-2">
                  <h4 className="card-title mb-1">{item.Title}</h4>
                  <p className="card-text">{item.Description}</p>
                </div>
              </div>
            </a>
          ))}
          {menuItems.length === 0 && (
            <div className="center-text">
              <img className="mx-auto d-block" src={spinner} alt="Logo" />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mx-auto p-5">
          <div className="form-group text-center">
            <button type="button" className="btn btn-link" onClick={onSignOut}>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withOktaAuth(Menu);
