class BrandService {
  static getBrandClassPrefix(host) {

    let defaultPrefix = "hightower";

    let prefixes = {
      "synergycapitalsolutions": "synergy",
      "falconwealthadvisors": "falcon",
      "alinewealth": "aline",
      "treasurypartners": "tp",
      "morgiawm": "morgia",
      "harveyinvestmentmanagement": "harvey",
      "thebahnsengroup": "tbg",
      "fortresswealthplanning": "fortress",
      "cogneticcapital": "cognetic",
      "hummermower": "hma",
      "somersetadvisory": "somerset",
      "lexingtonwealth": "lexington",
      "irongatepartnersinc": "irongate",
      "dsgadvisors": "dsg",
      "abc": "abc"
    }

    for (const key in prefixes) {
      if (host.includes(key)) {
        return prefixes[key];
      }
    }

    //return 'dsg';
    return defaultPrefix;
  }
}

export default BrandService;
