import http from "./httpService";

class MenuService {
  static getData(userId) {
    const myPromise = new Promise((resolve, reject) => {
      //let endpoint = "https://ht-client-portal-experience-prod.us-e2.cloudhub.io/api/v1/users/" + userId + "/menuItems";
      //let endpoint = "https://ht-client-portal-api-prod-v1.us-e2.cloudhub.io/api/v1/users/" + userId + "/menuItems";
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/v1/users/" + userId + "/menuItems";

      http.get(endpoint).then(response => {
        const items = response.data;
        //console.log("menu items", items);
        resolve(items);
      });
    });
    return myPromise;
  }
}

export default MenuService;
