import React from "react";

const NotFound = () => {
  return (
    <div className="container p-5">
      <div className="row justify-content-center">
        <div className="col-6-md text-center">
          <h2>404</h2>
          <p>Oops! We can't seem to find the page you are looking for.</p>
          <a href="/" className="btn btn-link">
            Back to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
