import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

const Logout = props => {
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    oktaAuth.tokenManager.clear();
    oktaAuth
      .signOut()
      .then(function () {
        console.log("successfully logged out");
      })
      .catch(function (err) {
        console.error("sign out error");
        console.error(err);
      })
      .then(function () {
        // redirect to root
        window.href = window.location.origin;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container p-5">
      <div className="row justify-content-center">
        <div className="col-6-md text-center">
          <h2>Logout</h2>
          <p>We've logged you out of the portal.</p>
          <a href="/" className="btn btn-link">
            Back to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Logout;
