import axios from "axios";

axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    console.error(error);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use(config => {
  return config;
});

const service = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};


export default service;